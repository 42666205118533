import React from 'react'
import { Helmet } from 'react-helmet'
import { PageProps } from 'gatsby'
import { withLanguageProvider } from '../context'

const Redirect = ({ pageContext: { to } }: PageProps<{}, { to: string }>) => {
  return (
    <Helmet>
      <meta name='robots' content='noindex' />
      <meta http-equiv='refresh' content={`0; url=${to}`} />
    </Helmet>
  )
}

export default withLanguageProvider(Redirect)
